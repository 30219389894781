import axios from 'axios'
import swal from 'sweetalert'
// import { ErrorTypes } from 'vue-router'

class CustomerFactory {

  async bill (data: any) {
    try {
      let url = '/customers/bill-users'
      var response = await axios.get(url, {
        params: {
          school_year_id: data.school_year_id,
          order_at: data.order_at
        }
      })
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async detailBill (id: any) {
    try {
      let url = `/customers/bill-users/${id}`
      var response = await axios.get(url)
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async checkoutOrder (data: any) {
    try {
      let url = '/customers/orders'
      var response = await axios.post(url, data)
      console.log(response)
      return response.data
    } catch (error) {
      if (error instanceof Error){
        if ('response' in error) {
          var messages: Array<string> = error['response']['data']['messages']
          swal(messages.join(', '), "", "error")
        }
      }
      return false
    }
  }

  async listOrder (data: any) {
    try {
      let url = '/customers/orders'
      var response = await axios.get(url, {
        params: {
          status: data.status,
          date_from: data.date_from,
          date_to: data.date_to,
          keyword: data.keyword,
          offset: data.offset,
          limit: data.limit,
          order_by: data.order_by,
          order_at: data.order_at
        }
      })
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async detailOrder (order_id:any) {
    try {
      let url = `/customers/orders/${order_id}`
      var response = await axios.get(url)
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async calcConvenienceFee (data: any) {
    try {
      let url = '/customers/convenience_fee'
      var response = await axios.post(url, data)
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async getCustomInvoice (order_id:any) {
    try {
      let url = `/customers/orders/${order_id}/invoice`
      var response = await axios.get(url)
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

}


export let customerFactory = new CustomerFactory()
